button,
input {
  padding: 10px;
  border: 1px solid black;
  cursor: pointer;
  background: #eee;
  border-radius: 5px;
}

.favoriteButton {
  margin-top: 10px;
}

input {
  width: 180px;
}
.App {
  text-align: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
}

.header {
  height: 50px;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid black;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.page {
  padding: 20px;
}

.editPage {
  display: flex;
  justify-content: center;
}

.edit {
  margin: 20px;
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.edit label {
  margin-right: 10px;
}

.edit button {
  margin-left: 99px;
}

.items {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.item {
  border: 1px solid black;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.item > div {
  width: 20%;
  min-width: 200px;
  border-right: 1px solid black;
}

.item > div:last-child {
  border-right: none;
}

.item img {
  max-width: 200px;
  max-height: 200px;
}

.item button {
  margin-right: 10px;
}

@media screen and (max-width: 1026px) {
  .item {
    flex-direction: column;
    align-items: center;
  }
  .item > div {
    border-right: none;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
  }
}
